import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/packages/mdx/src/components/Layout/Default.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ButtonInternal = makeShortcode("ButtonInternal");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "hallenverhuur-nancy-en-metz"
    }}><strong parentName="h1">{`Hallenverhuur Nancy en Metz`}</strong></h1>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/810e3e45a087fdb16eb9a8b786cc7317/41913/LocationSalles3.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "24.539877300613497%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAIAAADKYVtkAAAACXBIWXMAAAsTAAALEwEAmpwYAAABPElEQVQY0wExAc7+AGxVNl9GJFY5FGBBG2VEHWtLJGtIIHhfQYRxWIqAZqSfkqKKaJ59WrKiksi+r/nw3aFxSK+EWevgzPLk0QB5aE1kTi5XOxZcPhlcOxZpSCBgPRd2XkJ5Z094a1GgloanknSdfFe1n4jAsqXx6t2gb0W2h1rz59X07N4AeWxWY1E2YEstbFg/b1g+b1Y4Z0wsfGpVgHlvYFhOeW1fnIVrmn1fqY58d0Y/vrGnwKWS08Cv9O/o8OznAHp3cXNpW29lV3NkU3RnWnpuY6mnpXZhT6OZjJiYjZ2UiIdqU5yHc7Kil6eOf9PNyrSsqLa0sMbAvc/JxwBhVUtxZFu6xs6BfndMOCNSQS+Fe3B3YlCUi4Ojo6CrrKuloJygloipmY2lj37Dw8CQgnN5Y0ualI6ho6bjlZg/eUwLgQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/810e3e45a087fdb16eb9a8b786cc7317/f994e/LocationSalles3.webp 163w", "/static/810e3e45a087fdb16eb9a8b786cc7317/bcd70/LocationSalles3.webp 325w", "/static/810e3e45a087fdb16eb9a8b786cc7317/5d8d7/LocationSalles3.webp 650w", "/static/810e3e45a087fdb16eb9a8b786cc7317/c4443/LocationSalles3.webp 975w", "/static/810e3e45a087fdb16eb9a8b786cc7317/5cc32/LocationSalles3.webp 1300w", "/static/810e3e45a087fdb16eb9a8b786cc7317/7c4f2/LocationSalles3.webp 2048w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/810e3e45a087fdb16eb9a8b786cc7317/cf3f8/LocationSalles3.png 163w", "/static/810e3e45a087fdb16eb9a8b786cc7317/bb21a/LocationSalles3.png 325w", "/static/810e3e45a087fdb16eb9a8b786cc7317/70ccf/LocationSalles3.png 650w", "/static/810e3e45a087fdb16eb9a8b786cc7317/b996f/LocationSalles3.png 975w", "/static/810e3e45a087fdb16eb9a8b786cc7317/ff5cf/LocationSalles3.png 1300w", "/static/810e3e45a087fdb16eb9a8b786cc7317/41913/LocationSalles3.png 2048w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/810e3e45a087fdb16eb9a8b786cc7317/70ccf/LocationSalles3.png",
              "alt": "Huren van bruidskamers Nancy Metz",
              "title": "Huren van bruidskamers Nancy Metz",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span><strong parentName="p">{`Huren van bruidskamers Nancy Metz`}</strong></p>
    <h2 {...{
      "id": "een-wedding-locatie-in-lotharingen"
    }}>{`Een `}<strong parentName="h2">{`wedding`}</strong>{` locatie in `}<strong parentName="h2">{`Lotharingen`}</strong></h2>
    <h3 {...{
      "id": "organiseer-uw-evenement-tussen-nancy-en-metz"
    }}>{`Organiseer uw evenement tussen `}<strong parentName="h3">{`Nancy`}</strong>{` en `}<strong parentName="h3">{`Metz`}</strong>{`.`}</h3>
    <p>{`Het Château de Morey verwelkomt uw recepties op zijn authentieke en warme plaatsen. Maak van uw evenementen unieke momenten.`}</p>
    <p>{`3 zalen: 1 zaal voor 60/65 volwassenen - 1 zaal voor 15/20 kinderen - 1 danszaal Een park van meer dan een hectare, met bomen van honderd jaar oud en zingende vogels brengt u elders.`}</p>
    <ButtonInternal to="/location-salles-nancy-metz/brochure/" mdxType="ButtonInternal">Vraag uw brochure aan</ButtonInternal>
    <h2 {...{
      "id": "weddings-recepties-en-cocktails-in-de-buurt-van-nancy"
    }}><strong parentName="h2">{`Weddings`}</strong>{`, `}<strong parentName="h2">{`recepties`}</strong>{` en cocktails in de buurt van `}<strong parentName="h2">{`Nancy`}</strong></h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "640px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/2b4e4e518b5a879bf4410b0cf46bc522/d6032/chateau_de_morey_mariage.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.25766871165644%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAMBAgT/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAf/aAAwDAQACEAMQAAAB2UIGCCT/xAAZEAADAAMAAAAAAAAAAAAAAAAAAREQITH/2gAIAQEAAQUCWhM7iEP/xAAVEQEBAAAAAAAAAAAAAAAAAAAAIf/aAAgBAwEBPwFX/8QAFREBAQAAAAAAAAAAAAAAAAAAACH/2gAIAQIBAT8BV//EABgQAAIDAAAAAAAAAAAAAAAAAAABESBR/9oACAEBAAY/AnI9r//EABsQAAMBAAMBAAAAAAAAAAAAAAABESExQXGR/9oACAEBAAE/IXdWq6GxLp5TYIex65f0/9oADAMBAAIAAwAAABCEL//EABcRAQADAAAAAAAAAAAAAAAAAAABQWH/2gAIAQMBAT8QpOH/xAAYEQACAwAAAAAAAAAAAAAAAAAAEQEhQf/aAAgBAgEBPxBWPEH/xAAcEAEAAwADAQEAAAAAAAAAAAABABEhMUGB4fD/2gAIAQEAAT8Qojs8EMc2HqDPswXLVKbsS1rnHcfwQ3XjSf/Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/2b4e4e518b5a879bf4410b0cf46bc522/f994e/chateau_de_morey_mariage.webp 163w", "/static/2b4e4e518b5a879bf4410b0cf46bc522/bcd70/chateau_de_morey_mariage.webp 325w", "/static/2b4e4e518b5a879bf4410b0cf46bc522/797b9/chateau_de_morey_mariage.webp 640w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/2b4e4e518b5a879bf4410b0cf46bc522/367e5/chateau_de_morey_mariage.jpg 163w", "/static/2b4e4e518b5a879bf4410b0cf46bc522/ab07c/chateau_de_morey_mariage.jpg 325w", "/static/2b4e4e518b5a879bf4410b0cf46bc522/d6032/chateau_de_morey_mariage.jpg 640w"],
              "sizes": "(max-width: 640px) 100vw, 640px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/2b4e4e518b5a879bf4410b0cf46bc522/d6032/chateau_de_morey_mariage.jpg",
              "alt": "Bedrijfsseminars nancy en metz",
              "title": "Bedrijfsseminars nancy en metz",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span><strong parentName="p">{`Bedrijfsseminars nancy en metz`}</strong></p>
    <h3 {...{
      "id": "je-vakbond-vieren-je-verjaardag-brunchen-met-je-familie--in-de-buurt-van-metz-en-nancy"
    }}>{`Je vakbond vieren? Je verjaardag? Brunchen met je familie ? in de buurt van `}<strong parentName="h3">{`Metz`}</strong>{` en `}<strong parentName="h3">{`Nancy`}</strong></h3>
    <p>{`Verwonder uzelf en uw gasten door een avond in het Château de Morey door te brengen. De traditionele stenen muren van onze kamers dompelen u onder in een chique en verfijnde sfeer. Tussen Nancy en Metz kan uw evenement elke stijl aannemen, middeleeuws, verfijnd, modern, landelijk, bohémien… Pas onze plaatsen aan volgens uw smaak. Het is echter altijd leuk om te onthouden dat een charmante ontvangstruimte minder decoratiekosten met zich meebrengt.`}</p>
    <p>{`Wij ontvangen al meer dan 20 jaar bruiloften in Lotharingen, dus het is met plezier dat wij u begeleiden tijdens de organisatie van uw evenement. We zijn al jaren vertrouwd met dit gebied, maar we blijven ons verbazen over alle bruiloften die we ontvangen, dankzij uw authenticiteit.`}</p>
    <p>{`In Meurthe-et-Moselle is het mogelijk om van uw evenement een uniek souvenir te maken. We hebben een bucolisch park van meer dan een hectare, dat ruimte biedt tijdens uw recepties. Waar u uw kinderen kunt zien rennen tussen onze Libanese ceders, uw familie kunt zien toasten terwijl u kijkt naar het uitzonderlijke uitzicht op de vallei… Geniet van een familiemoment in een groot en warm huis dat u het gevoel geeft uw geliefden thuis te ontvangen.`}</p>
    <ButtonInternal to="/location-salles-nancy-metz/mariage-clef-main-a-nancy/" mdxType="ButtonInternal">Bruiloftspakket</ButtonInternal>
    <ButtonInternal to="/location-salles-nancy-metz/mariages/" mdxType="ButtonInternal">Bruiloften</ButtonInternal>
    <h2 {...{
      "id": "uw-professionele-evenementen-op-enkele-minuten-afstand-van-nancy-en-metz"
    }}>{`Uw professionele evenementen op enkele minuten afstand van `}<strong parentName="h2">{`Nancy`}</strong>{` en `}<strong parentName="h2">{`Metz`}</strong></h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/810e3e45a087fdb16eb9a8b786cc7317/41913/LocationSalles3.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "24.539877300613497%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAIAAADKYVtkAAAACXBIWXMAAAsTAAALEwEAmpwYAAABPElEQVQY0wExAc7+AGxVNl9GJFY5FGBBG2VEHWtLJGtIIHhfQYRxWIqAZqSfkqKKaJ59WrKiksi+r/nw3aFxSK+EWevgzPLk0QB5aE1kTi5XOxZcPhlcOxZpSCBgPRd2XkJ5Z094a1GgloanknSdfFe1n4jAsqXx6t2gb0W2h1rz59X07N4AeWxWY1E2YEstbFg/b1g+b1Y4Z0wsfGpVgHlvYFhOeW1fnIVrmn1fqY58d0Y/vrGnwKWS08Cv9O/o8OznAHp3cXNpW29lV3NkU3RnWnpuY6mnpXZhT6OZjJiYjZ2UiIdqU5yHc7Kil6eOf9PNyrSsqLa0sMbAvc/JxwBhVUtxZFu6xs6BfndMOCNSQS+Fe3B3YlCUi4Ojo6CrrKuloJygloipmY2lj37Dw8CQgnN5Y0ualI6ho6bjlZg/eUwLgQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/810e3e45a087fdb16eb9a8b786cc7317/f994e/LocationSalles3.webp 163w", "/static/810e3e45a087fdb16eb9a8b786cc7317/bcd70/LocationSalles3.webp 325w", "/static/810e3e45a087fdb16eb9a8b786cc7317/5d8d7/LocationSalles3.webp 650w", "/static/810e3e45a087fdb16eb9a8b786cc7317/c4443/LocationSalles3.webp 975w", "/static/810e3e45a087fdb16eb9a8b786cc7317/5cc32/LocationSalles3.webp 1300w", "/static/810e3e45a087fdb16eb9a8b786cc7317/7c4f2/LocationSalles3.webp 2048w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/static/810e3e45a087fdb16eb9a8b786cc7317/cf3f8/LocationSalles3.png 163w", "/static/810e3e45a087fdb16eb9a8b786cc7317/bb21a/LocationSalles3.png 325w", "/static/810e3e45a087fdb16eb9a8b786cc7317/70ccf/LocationSalles3.png 650w", "/static/810e3e45a087fdb16eb9a8b786cc7317/b996f/LocationSalles3.png 975w", "/static/810e3e45a087fdb16eb9a8b786cc7317/ff5cf/LocationSalles3.png 1300w", "/static/810e3e45a087fdb16eb9a8b786cc7317/41913/LocationSalles3.png 2048w"],
              "sizes": "(max-width: 650px) 100vw, 650px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/static/810e3e45a087fdb16eb9a8b786cc7317/70ccf/LocationSalles3.png",
              "alt": "Huren van bruidskamers Nancy Metz",
              "title": "Huren van bruidskamers Nancy Metz",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span><strong parentName="p">{`Huren van bruidskamers Nancy Metz`}</strong></p>
    <h3 {...{
      "id": "teambuilding--conferentie--bedrijfsfeestje--residentieel-seminarie-"
    }}>{`Teambuilding ? Conferentie ? Bedrijfsfeestje ? Residentieel seminarie ?`}</h3>
    <p>{`Op het Château de Morey worden speciale bedrijfspakketten aangeboden. In Lotharingen kunt u van deze plaats gebruik maken om uw personeel, uw partners te informeren, te bedanken en aan te moedigen…`}</p>
    <p>{`Geniet van een professioneel moment, in een warme omgeving tussen Nancy en Metz. Doe mee met het nuttige aan het aangename, wij zorgen voor de organisatie van uw evenementen:`}</p>
    <p>{`Menu’s naar ieders smaak en budget
Opstellingen aangepast aan uw vergaderingen
Een lijst met aanbieders om uw teams te vermaken
Van avondcocktails tot residentiële seminaries, we verwelkomen u met veel plezier. Onze 5 gastenkamers bieden u de mogelijkheid om te profiteren van accommodatie indien nodig tijdens uw evenement.`}</p>
    <p>{`De charme van het Château combineert ook rust en ontspanning met het bosrijke park van meer dan een hectare. Een comfortabele ruimte, midden in de natuur en toch dicht bij Nancy en Metz.`}</p>
    <ButtonInternal to="/location-salles-nancy-metz/brochure/" mdxType="ButtonInternal">Ontvang uw offerte</ButtonInternal> 
    <h2 {...{
      "id": "onze-evenementen-in-de-buurt-van-nancy"
    }}>{`Onze evenementen in de buurt van `}<strong parentName="h2">{`Nancy`}</strong></h2>
    <h3 {...{
      "id": "we-houden-ook-van-feesten"
    }}>{`We houden ook van feesten…`}</h3>
    <p>{`Het is ons een genoegen om een goede tijd met u te delen. Elk jaar organiseren we een tiental evenementen. Breng een levendige avond door tussen Nancy en Metz.`}</p>
    <p>{`Nieuwjaarsdag, Valentijnsdag, Brunch, Diner in het donker, Dinnershow… Geniet in Lotharingen van een feestelijk moment, onder begeleiding van uw familie, vrienden, metgezellen of kinderen. Onze evenementen spreken alle leeftijden aan! De grote ruimte van het Château de Morey biedt u de mogelijkheid om een warm en gezellig moment door te brengen voor alle gelegenheden.`}</p>
    <p>{`Het is een plezier om u onze partners te laten ontdekken tijdens deze evenementen, de zachte stemmen van onze favoriete zangers, de gerechten van onze favoriete traiteurs …`}</p>
    <p>{`U kunt lid worden van onze gemeenschap om op de hoogte te blijven van elk van onze evenementen.`}</p>
    <ButtonInternal to="/nl/newsletter/" mdxType="ButtonInternal">Word lid van onze nieuwsbrief</ButtonInternal>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      